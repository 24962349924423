<template>
	<div class="mb-5">
  <div class="flex" flex-row>
    <div>
      <button v-on:click="exportPreferences" class="border border-theme-offwhite-200 p-3 rounded  inline-flex flex-row items-center  bg-theme-secondary-200 text-white">
       Export
      </button>
    </div>
    <div>
    <div v-if="maxObjects >= 1" class="tag-list-trigger w-auto">
 
      <button v-on:click="showFilterDropdown" class="btn-ui-no-margin">
        Filter
      </button>
    
      <div v-if="showFilterList">
        <div class="bottom-0 top-0 left-0 right-0 fixed" v-on:click="closeFilterDropdown"  ></div>
        <div class="w-auto left-10 pr-2 py-2 bg-white  shadow-xl tag-list">

          <label class="checkbox-label font-normal" v-for="n  in parseInt(maxObjects)">
            <input v-bind:value="n" v-model="filter"type="checkbox">{{ n }}e voorkeur
            <div class="checkbox-check"></div>
          </label>

        </div>
      </div>
    </div>
  </div>
    
    </div>


    <table class="table-fixed w-full table table-header-rotated">
      <thead>
        <tr class="csstransforms">
          <th  class="w-1/2 px-4 py-2"></th>
          <th  v-for="(name, id) in residentialObjects" class="rotate">
            <div>
                <p class="border-b text-theme-secondary-100" >{{ name }}</p>
                <!-- <a class="cursor-pointer text-theme-secondary-100 hover:text-theme-primary-100" v-on:click="openContactsInRow(id)">{{ name }}</a> -->
              </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(preference, name) in preferences">
          <td v-on:click="openContact(preference.uid)" class="border px-4 py-2 text-left cursor-pointer text-theme-secondary-100 hover:text-theme-primary-100">{{ name }}</td>
          <!-- With filter -->
          <template v-if="filter.length" v-for="(place, residentialObbjectId) in preference.prefrences">
            <td class="border px-4 py-2 text-center align-middle"><span v-if="place && filter.includes(place)">{{ place }} </span></td>
          </template>

          <!-- without filter -->
          <template v-if="!filter.length" v-for="(place, residentialObbjectId) in preference.prefrences">
            <td class="border px-4 py-2 text-center align-middle"><span v-if="place">{{ place }} </span></td>
          </template>
        </tr>

      </tbody>
    </table>
  </div>
</template>


<script>
  const axios = require('axios');

  export default {
    props: ['subPlanUid', 'maxObjects', 'exportRoute'],

    data: function() {
      return {	
        preferences: null,
        residentialObjects: null,
        showFilterList: false,
        filter: [],
      }
    },
    methods: {
     init(){
      var vm = this;
      axios.post('/api/preferencestable', null, { params: {
        SubPlanUid: vm.subPlanUid,
      }}).then(function (response) {
        if(response.data){
          console.log(response.data.contact_preferences);
          vm.preferences = response.data.contact_preferences
          vm.residentialObjects = response.data.residential_objects
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    showFilterDropdown(){
      this.showFilterList = true;
    },
    closeFilterDropdown(){
      this.showFilterList = false;
    },
    openContactsInRow(Id){
      window.location = window.location+'/'+Id;
    },
    exportPreferences(){
       window.open(this.exportRoute,'_blank');
    },
    openContact(Uid){
      var str = window.location.toString();
      window.location = str.substring(0, str.length - 11) + 'contact/' +  Uid
    }

  },
  mounted() {
    this.init();
  },

}
</script>