<template>
	<div>
		<div class="flex flex-row my-3 justify-between items-center">
			<label class="w-1/4 capitalize">{{ label }}</label>
			<input :name="name" :value="value" type="number"  v-bind:class="{ 'border-red-300': this.errorMsg }" class="form-input flex-grow rounded border p-3" >
		</div>
		<div v-if="this.errorMsg" class="text-red-500 text-right -mt-12 mb-6 pb-1 mr-5 z-10">
			{{this.errorMsg}}
		</div> 
	</div>
</template>

<script>
	export default {
		data() {
			return {
				errorMsg: null
			}
		},
		props: ['label','name','value', 'errors'],
		mounted: function() {
			var error = JSON.parse( this.errors );
			var name = this.name.replace("[", ".").replace("]", "");
			if(error[name]){
				this.errorMsg = error[name][0]
			}
		},
	}
</script>