<template>

  <div class="w-full h-full ">
    <div class="map " id="map-render"></div>
  </div>

</template>

<script>
  const axios = require('axios');
   const accesToken = document.querySelector('meta[name="mapbox_access_token"]').content;
  mapboxgl.accessToken = accesToken;

  export default {
   data () {
    return {
      map: null
    }
  },
  props: ['data-source',  'save-route', 'max-zoom'],
  methods: {
    initMap(){
      this.map = new mapboxgl.Map({
        container: 'map-render',
        center: [5.613478 ,52.206921],
        zoom: 9
      })

      this.map.setStyle('mapbox://styles/wilfredlepelaar/ck3a1cnms01mm1cr39tntl18i');
      return this.initMarkers();
    },

    initMarkers(){
      var vm = this;

      const bounds = new mapboxgl.LngLatBounds();

      axios.get(vm.dataSource).then(function (response) {

        for (var i = 0; i < response.data.length; i++) {
          var MarkerHref = document.createElement('a');
          MarkerHref.className = 'map-marker-no-route';

          const marker = new mapboxgl.Marker(MarkerHref).setLngLat(response.data[i]['location']).addTo(vm.map);

          
          bounds.extend(response.data[i]['location']);
        }
        const zoom = vm.maxZoom ? vm.maxZoom : 9;
        vm.map.fitBounds(bounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
          maxZoom: zoom,
          duration: 0
        });
        vm.map.addControl(new mapboxgl.NavigationControl());

      })
      .catch(function (error) {
        console.log(error);
      });
    },


  },


  mounted () {
    this.initMap();
    var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    this.map.on('click', event => {
      var NewMarker = document.querySelector(".map-marker-new");
      if(NewMarker){
        NewMarker.remove();
      }

      var Marker = document.createElement('div');
      Marker.className = 'map-marker-new';
      var popup = new mapboxgl.Popup({ offset: 12 })
      .setHTML('<div class="flex flex-row w-full justify-between items-center"> <div class="text-xs  px-2 text-white">Deze locatie opslaan als projectlocatie</div><div class="ml-auto"> <form method="POST" action="'+ this.saveRoute +'"> <input type="hidden" name="_token" value="'+ csrf +'"/> <input type="hidden" name="lat" value="' + event.lngLat.lat +'"/> <input type="hidden" name="lng" value="'+ event.lngLat.lng +'"/> <button class="bg-theme-primary-100 hover:bg-theme-primary-200 text-white font-bold py-2 px-4 rounded">Opslaan</button> </form> </div></div>')
      
      new mapboxgl.Marker(Marker).setLngLat(event.lngLat).setPopup(popup).addTo(this.map);
      popup.addTo(this.map)
    });


    this.map.on("wheel", event => {
      if (event.originalEvent.ctrlKey || event.originalEvent.altKey || event.originalEvent.metaKey) {
        return;
      }
      event.preventDefault();
    });

  }
}
</script>