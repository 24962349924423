<template>
	<div class="flex flex-col items-end">
		<label v-for="(message, index) in messages" :key="index" class="alert-toast" :class="{ show: message.showMe }" @click="hideMessage(message)">
			<div class="opacity-100 my-2 inline-flex items-center p-1 bg-green-600 leading-none text-white rounded-full p-0 shadow-xl text-teal text-sm">
				<span class="inline-flex px-5" v-html="message.msg"></span>
				<span class="inline-flex bg-green-300 text-green-800 rounded-full p-2 justify-center items-center">
					<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 24 24" height="24" width="24"><g transform="matrix(1,0,0,1,0,0)"><path d="M 11,21.5c0,1.105-0.895,2-2,2s-2-0.895-2-2 " stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path><path d="M 9.5,6.552C9.336,6.518,9.168,6.5,9,6.5C7.619,6.5,6.5,7.619,6.5,9 c-2.422,1.009-4,3.376-4,6v4.5c0,1.105-0.895,2-2,2h17c-1.105,0-2-0.895-2-2V15c-0.004-0.26-0.024-0.52-0.059-0.778 " stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path><path d="M 21.232,4.558 l-4.095,4.357l-2.925-2.84 " stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path><path d="M 17.5,0.501c3.314,0,6,2.686,6,6s-2.686,6-6,6s-6-2.686-6-6S14.186,0.501,17.5,0.501z" stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
				</span>
			</div>
		</label>

	</div>
</template>

<script>

export default {
	data() {
		return {
			messages: [],
		}
	},
	props: ['autoclose'],
	mounted: function() {
		let vm = this;
		let user = document.head.querySelector('meta[name="user"]');
		window.Echo.private('toast-channel.' + user.content).listen('.success', (data) => {
			var index = vm.messages.push({
				'msg': data.message,
				'showMe': true,
				'isVisible': true,
				'autoclose': data.autoclose
			});
			
			if( data.autoclose > 0 ) vm.autocloseMessage( vm.messages[index - 1] )

		});
	},
	methods: { 
		hideMessage(message){
			message.showMe = false;
			setTimeout(function () { message.isVisible = false; }, 500);
		},
		autocloseMessage(message){
			let vm = this;
			setTimeout(function () { vm.hideMessage( message ) }, message.autoclose);
		}
	}
}
</script>