export default class ChatMessages {
	constructor(name) {
		window[name] = this.data()
	}
	data() {
		return {
			chats: [],
			messages: [],
			attendees: [],
			contacts: [],
			ContactId: null,
			ActiveChat: null,
			newMessage: null,
			ShowContactList: false,
			showModal: false,
			toastDisplay: false,
			toastMessage: 'Bezig met laden...',
			query: '',
			HasAUserSeenChat: false,
			HasContactSeenChat: false,
			HasActiveChatNewMessages: false,

			initPuscher(UniqueChatId) {
				var vm = this;
				window.channel = pusher.subscribe('chat-channel-' + UniqueChatId);
				window.channel.unbind_all()
				window.channel.bind('ChatCreated', function(data) {
					vm.toastMessage = "Nieuwe bericht";
					vm.toastDisplay = true;
					vm.messages.push(data);
					vm.HasActiveChatNewMessages = true;
					vm.init();
					vm.scrollToBottom();
				});
			},
			scrollToBottom(vm = this) {
				setTimeout(function() {
					var objDiv = document.getElementById("messages");
					objDiv.scrollTop = objDiv.scrollHeight;
				}, 100)
			},
			init(UniqueChatId) {
				var vm = this;
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
						console.log(responce)
						vm.toastDisplay = false
						vm.chats = responce;
						vm.scrollToBottom()
					}

					if (this.readyState == 4 && this.status != 200) {
						console.log('ERROR!')
						console.log(this.responseText)
						console.log('EINDE ERROR!')
					}
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/chat', true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
						SubPlanId: window.SubPlanId,
					}));
				}, 100)

				if( UniqueChatId){
					vm.getChat(UniqueChatId)
				}
			},
			getChat(UniqueChatId) {
				var vm = this;
				var xhttp = new XMLHttpRequest();

				if( UniqueChatId == vm.ActiveChat){
					vm.scrollToBottom()
					return;
				}

				vm.toastMessage = "Even geduld...";
				vm.toastDisplay = true
				vm.messages = [];
				vm.attendees = [];
				vm.newMessage = null;
				vm.ActiveChat = UniqueChatId
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
						vm.toastDisplay = false
						vm.attendees = responce.attendees
						vm.ActiveChat = responce.Uid
						vm.scrollToBottom()
						vm.initPuscher(UniqueChatId);
						vm.messages = responce.messages
						vm.HasAUserSeenChat = responce.HasAUserSeenChat
						vm.HasContactSeenChat = responce.HasContactSeenChat
						vm.HasActiveChatNewMessages = responce.HasNewMessage
						
					}
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/chat/read', true);	
				xhttp.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);

				setTimeout(function() {
					xhttp.send(JSON.stringify({
						ChatId: UniqueChatId,
					}));
				}, 500)
			},
			getContacts() {
				var vm = this;
				if (vm.ShowContactList == false) return;
				var xhttp = new XMLHttpRequest();
				vm.ActiveChat = null
				vm.messages = [];
				vm.attendees = [];
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						vm.contacts = JSON.parse(this.responseText)
					}
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/chat/contacts/', true);
				xhttp.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
						SubPlanId: window.SubPlanId,
					}));
				}, 100)
			},
			createChat(ContactId) {
				var vm = this;
				vm.query = "";
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
						if( responce.IsNew){
							vm.chats.unshift( responce )
						}						
						vm.ActiveChat = responce.Uid
						vm.messages = responce.messages
						vm.ContactId = responce.ContactId
						vm.ShowContactList = false
						vm.scrollToBottom()
						vm.initPuscher(responce.Uid);
					}
				}
				xhttp.open('post', '/api/chat/create', true);
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				xhttp.send(JSON.stringify({
					SubPlanId: window.SubPlanId,
					ContactId: ContactId,
				}));
			},   
			sendMessage() {
				var vm = this;
				var xhttp = new XMLHttpRequest();

				setTimeout(()=>{
					vm.newMessage = null;
					vm.$refs.trixInput.value = '';
				},1000)
				vm.HasContactSeenChat = false;
				

				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 201) {
						var responce = JSON.parse(this.responseText)
						vm.newMessage = null;
						$refs.editor.value = '';
						vm.init();
						vm.getChat(responce.Uid)
					}
				}
				xhttp.open('post', '/api/chat/send', true);
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				xhttp.send(JSON.stringify({
					Message: this.newMessage,
					ChatId: vm.ActiveChat,
					SubPlanId: window.SubPlanId,
					ContactId: vm.ContactId,
				}));
			},
			autosize(){
				var el = this.$refs.refTextarea;
				el.style.cssText = 'height: auto; padding: 0;';
				el.style.cssText = '-moz-box-sizing:border-box';
				el.style.cssText = 'padding: 20px 20px 0px 20px; height: calc(' + el.scrollHeight+'px + 40px)';
			},
			filterContact(){
				var resultArray = [];
				for(var i=0; i < this.contacts.length; i++){
					if(this.contacts[i].FullName.toLowerCase().indexOf(this.query.toLowerCase()) !== -1){
						resultArray.push(this.contacts[i]); 
					}
				}
				return resultArray
			},
			fetchContacts(){
				if (this.query === "") {
					return this.contacts
				}

				return this.filterContact()
			},
			flagChat(UniqueChatId, State){
				var vm = this;
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/chat/flag', true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
						Chat: UniqueChatId,
						State: State,
					}));
				}, 100)
			},
			shouldSendMessage(event) {
				if (event.keyCode == 13 && !event.shiftKey) {
					this.sendMessage()
				}
			},
			deleteMessage(MessageUid){
				var vm = this;

				var xhttp = new XMLHttpRequest();

				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
						var index = vm.FindWithAttr(vm.messages, 'Uid', MessageUid);
						vm.messages.splice(index, 1)
					}
				}

				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/chat/delete/', true);
				xhttp.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
						MessageUid: MessageUid,
						ChatId: vm.ActiveChat,
						SubPlanId: window.SubPlanId,

					}));
				}, 100)
			} ,

			FindWithAttr(array, attr, value) {
				for(var i = 0; i < array.length; i += 1) {
					if(array[i][attr] === value) {
						return i;
					}
				}
				return -1;
			},
			formatMessage(message){
				message = this.nl2br(message)
				if(!this.isEmail()){
					message = this.makeAnchorTag(message);
				}
				return message
			},
			makeAnchorTag(message){

				// Check if message contains a link. If so give the link some fancy styling
				var isAlreadyAnchor =  /^\<a.*\>.*\<\/a\>/i.test(message);
    			if(!isAlreadyAnchor){
            		var Rexp =/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
            		message = message.replace(Rexp,"<a class='text-blue-600 underline hover:text-blue-500' target='_blank' href='$1' target='_blank'>$1</a>");
        		}

			return message;

			},
			nl2br (message, replaceMode, isXhtml) {
 			 	var breakTag = (isXhtml) ? '<br />' : '<br>';
  				var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
  				return (message + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
			},
			isEmail(email) {
    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());
			},
			readMessage(UniqueChatId){
				var vm = this;
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					vm.HasActiveChatNewMessages = false;
					vm.init(UniqueChatId);
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/chat/mark-read', true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
						Chat: UniqueChatId,
					}));
				}, 100)
			},

		}
	}
}
