export default class MailTest {
	constructor(name) {
		window[name] = this.data()
	}
	data() {
        return {
            showTestButton: true,
            sendingMessage: 'Aan het versturen..',
            routeName: '',

            init(route) {
                this.routeName = route
                console.log(this.routeName)
            },

            sendTestMail(){
                var vm = this;
                vm.showTestButton = false;
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
                        if(responce.status == 'ok'){
                            vm.showTestButton = true;
                        }
					}
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', vm.routeName, true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send();
				}, 100)
            }
        }
    }
}