<template>
	<label class="alert-toast" v-bind:class="{ show: showMe }"  v-show="isVisible" @click="toggleShowMe()">
		<div class="opacity-75 hover:opacity-100 my-2 inline-flex items-center p-1 bg-theme-secondary-100 leading-none text-white rounded-full p-0 shadow-xl text-teal text-sm">
			<span class="inline-flex px-5">{{message}}</span>
			<span class="inline-flex bg-green-300 text-green-800 rounded-full p-2 justify-center items-center">
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 24 24" height="30" width="30"><g transform="matrix(1,0,0,1,0,0)"><path d="M 11,21.5c0,1.105-0.895,2-2,2s-2-0.895-2-2 " stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path><path d="M 9.5,6.552C9.336,6.518,9.168,6.5,9,6.5C7.619,6.5,6.5,7.619,6.5,9 c-2.422,1.009-4,3.376-4,6v4.5c0,1.105-0.895,2-2,2h17c-1.105,0-2-0.895-2-2V15c-0.004-0.26-0.024-0.52-0.059-0.778 " stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path><path d="M 21.232,4.558 l-4.095,4.357l-2.925-2.84 " stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path><path d="M 17.5,0.501c3.314,0,6,2.686,6,6s-2.686,6-6,6s-6-2.686-6-6S14.186,0.501,17.5,0.501z" stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
			</span>
		</div>
	</label>
</template>

<script>
export default {
	data() {
		return {
			showMe: true,
			isVisible: true,
			isTouched: false
		}
	},
	props: ['autoclose','delayed', 'message'],
	mounted: function() {
		if( this.delayed ){
			this.showMe = false;
			this.isVisible = false;
			setTimeout(function () { this.toggleShowMe(); this.isTouched = false }.bind(this), this.delayed)	
		}
		if( this.autoclose ){
			setTimeout(function () { this.toggleShowMe() }.bind(this), this.autoclose)	
		}
	},
	methods: { 
		toggleShowMe(){
			if( this.isTouched ) return false;
			this.isTouched = true
			this.showMe = ! this.showMe;
			setTimeout(function () { this.toggleIsVisible() }.bind(this), 500)
		},
		toggleIsVisible(){
			this.isVisible = this.showMe 
		}
	}
}
</script>