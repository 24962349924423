<template>
	<div class="example">
		<apexchart ref="demoChart" type="heatmap"  :width="this.chartWidth" :height="this.chartHeight"  :options="chartOptions" :series="series">{{ }}</apexchart>
	</div>
</template>


<script>
import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'


export default {
	components: {
		apexchart: VueApexCharts,
	},

	props: ['label','json','color', 'chartWidth', 'chartHeight'],

	data: function() {
		return {	
			series: [],
			chartOptions: {

				stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'butt',
					colors: ['#fff'],
					width: 6,
					dashArray: 0,      
				},
				plotOptions: {
					heatmap: {
						radius: 5,
						shadeIntensity: 1,
						enableShades: true,
						reverseNegativeShade: true,
						  // colorScale: {
							   // inverse: true,
						  // }
						  // distributed: true,
						    // min: 0,
          		  // max: 5
              }
            },
            chart: {
             animations: {
              enabled: true
            },
            events: {
              dataPointSelection: function(event, chartContext, config) {

               var el = event.target;
          					// Werkt nog niet 
          					// el.classList.add = 'hidden';
          					var seriesIndex = parseInt(el.getAttribute("i"));
          					var dataPointIndex = parseInt(el.getAttribute("j"));
          					var residentialObjectID = config.w.globals.seriesZ[seriesIndex][0]
          					var keuze = dataPointIndex + 1
          					console.log(residentialObjectID, keuze)
          					window.location = window.location+'/'+residentialObjectID+'/'+keuze;
          				},
          			},

          		},
          		tooltip: {
          			enabled: false,
          		},
          		parentHeightOffset: 5,
          		xaxis: {
               position: 'top',
               labels: {
                style: {
                 fontSize: '15px'
               }
             }
           },
           yaxis: {
             labels: {
              align: 'left',
              style: {
               fontSize: '15px'
             }
           }
         },
         dataLabels: {
           enabled: true,
           textAnchor: 'start',
           style: {
            colors: ['rgba(0,0,0,0.5)'],
            fontWeight: 'light',
            fontSize: '15px'
          },
          formatter: function (val, opt) {
            return val
          },

        },

        colors: [function({ value, seriesIndex, w }) {

        				// todo: bereken gemiddelde 
        				// w.globals.seriesTotals.reduce ... / aantel series
        				// als x procent onder gem dan rood.
        				// var totaalVoorkeur123 = w.globals.series[seriesIndex].slice(0, 2).reduce((a, b) => a + b, 0)
        				var eersteVoorkeur = w.globals.series[seriesIndex].slice(0, 1).reduce((a, b) => a + b, 0)
        				var tweedeVoorkeur = w.globals.series[seriesIndex].slice(1, 2).reduce((a, b) => a + b, 0)

        				if ( eersteVoorkeur <= 3 && eersteVoorkeur + tweedeVoorkeur <= 5) {
        					return '#D9534F'
        				}
        				if ( eersteVoorkeur >= 5 || eersteVoorkeur + tweedeVoorkeur >= 10) {
        					return '#7cc65b'
        				}
        				return '#FFFFFF'
        			}]

        		},


        	}
        },
        methods: {
        	makeChart(){
        		var json = JSON.parse(this.json);
        		return this.walkOverJson(json);
        	},
        	compare(a, b) {
        		var index = (this.sortingIndex + 1 ).toString() 
        		console.log( index )
        		var bandA = a.data[index]
        		var bandB = b.data[index]

        		let comparison = 0;
        		if (bandA > bandB) {
        			comparison = 1;
        		} else if (bandA < bandB) {
        			comparison = -1;
        		}
        		return comparison;
        	},
        	walkOverJson(json){
        		var arr = []

        		for (let i = 0; i < json.length; i++) {
        			var data = this.generateData(10, json[i].data, json[i].id)
        			arr.push(
        			{
        				id: json[i].id,
        				name: [json[i].name,  '(Totaal: ' + data.total + ')'],
        				data: data.series
        			}
        			)
        		}

        		return arr;
        	},
        	generateData(count, object_data, id) {
        		var series = [];
        		if(!object_data) return series;
        		var values = Object.values(object_data)
        		var total = 0;
        		for (let i = 0; i < count; i++) {
        			var x = (i + 1).toString() + 'e';
        			total = total + values[i];
        			series.push({
        				x: x,
        				y: values[i],
        				z: id
        			});
        		}

        		return { series: series, total: total };
        	},

        	updateChart(seriesIndex) {
        		var vm = this
        		var json = JSON.parse(vm.json);	
        		this.sortingIndex = seriesIndex
        		json.sort(vm.compare);
            this.$refs.demoChart.updateSeries(this.walkOverJson(json))
            vm.SetClickEvents()
            document.getElementById('main-content').parentNode.scrollTop = 0
          },

          openContactsInRow(openContactsInRow){
              var json = JSON.parse(this.json);
              var residentialObjectID = json[openContactsInRow].id
              window.location = window.location+'/'+residentialObjectID;
          },

          SetClickEvents(){
            var vm = this
            var labels = document.querySelectorAll('.apexcharts-xaxis-texts-g .apexcharts-text.apexcharts-xaxis-label');

            for(let seriesIndex = 0 ; seriesIndex < labels.length ; seriesIndex++) {
             labels[seriesIndex].addEventListener('click', function() {
              vm.updateChart(seriesIndex)
            });
           }

           var rows = document.querySelectorAll('.apexcharts-yaxis-texts-g .apexcharts-text.apexcharts-yaxis-label');
          Array.from(rows).reverse().forEach( function(element, index) {
          element.addEventListener('click', function() {
              vm.openContactsInRow(index)
            });
          });

        }
      },
      mounted: function () {
       var vm = this
       vm.series =  vm.makeChart()

       const observer = new MutationObserver( list => {
        const evt = new CustomEvent('dom-changed', {detail: list});
        document.body.dispatchEvent(evt)
      });
       observer.observe(document.body, {attributes: false, childList: true, subtree: true});

       document.body.addEventListener('dom-changed', e => {
          setTimeout(function(){ vm.SetClickEvents() },100)
       } );
     },
     updated: function () {
       var vm = this
        vm.SetClickEvents()
     }

   }
   </script>