export default class Repeater {
    constructor(name) {
        window[name] = this.data()
    }
    data() {
        return {
            repeaters: [],

            init(errors){
                this.addRepeater('todoAdded');
            },

            addRepeater(dispatchEvent = null) {
                // add dynamically repeater fields
                this.repeaters.push({
                    id: `new_${this.repeaters.length}`,
                });

                // dispatch event
                if (dispatchEvent !== null) {
                    this.dispatchEvent(dispatchEvent);
                }
            },

            removeRepeater(index, dispatchEvent = null) {
                this.repeaters.splice(index);
            },

            remove(id, prefix = '', dispatchEvent = null) {
                // remove existing fields (eg: in a update view)
                document.getElementById(`${prefix}${id}`).remove();

                // dispatch event
                if (dispatchEvent !== null) {
                    this.dispatchEvent(dispatchEvent);
                }
            },

            dispatchEvent(dispatchEvent) {
                window.dispatchEvent(new CustomEvent(`${dispatchEvent}`));
            },
        };
    }
}



