<template>
	<div>
		<div class="tag-list-trigger w-auto">

			<button v-on:click="showFilterDropdown" class="btn-ui-no-margin">
				Filter
			</button>

			<div v-if="showFilterList">
				<div class="bottom-0 top-0 left-0 right-0 fixed" v-on:click="closeFilterDropdown"  ></div>
				<div class="w-auto left-10 pr-2 py-2 bg-white  shadow-xl tag-list">
					<label class="checkbox-label font-normal" v-for="category  in categories">
						<input v-bind:value="category"  v-model="filter" type="checkbox">{{ category }}
						<div class="checkbox-check"></div>
					</label>
				</div>
			</div>
		</div>

		<div class="flex justify-start items-center">
			<div class="w-1/2 py-6 px-6">
				<!-- Without filter -->
				<ul v-if="!filter.length" class="list-none  py-2 px-1 " style="border-left:4px solid #4f5169;">
					<li v-for="event in events" class="p-5">
						<div class="bg-theme-offwhite-200 border-4 border-theme-secondary-200 mr-4 text-theme-secondary-200 rounded-full circle-timeline">
							<svg  xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 20 20" width="20" height="20"><g transform="matrix(0.8333333333333334,0,0,0.8333333333333334,0,0)"><path d="M13.5,9a5.5,5.5,0,1,0-7,5.287V15.5l-1,1,1,1v1l-1,1,1,1V22L8,23.5,9.5,22V14.287A5.5,5.5,0,0,0,13.5,9Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.000 7.498 A2.000 2.000 0 1 0 10.000 7.498 A2.000 2.000 0 1 0 6.000 7.498 Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.5,15.013a5.507,5.507,0,0,0,5.425.033L16,16v1.5h1.5l.5.525V19.5h1.317l1.062,1H22.5V18.379l-5.454-5.454a5.5,5.5,0,0,0-5.5-8.133" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8,7V2.5a2,2,0,0,1,4,0V4.753" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
						</div>
						<div class="ml-5 rounded-lg shadow bg-white">
							<div class="p-4">
								<p class="pb-2 font-semibold">{{ event.category }}</p>
								<p class="font-light">{{ event.event_string }}</p>
							</div>
						</div>
					</li>
				</ul>

				<!-- With filter -->
				<ul v-if="filter.length">
					<li v-for="event in events" class="p-5">
						<div v-if="filter.includes(event.category)">
							<div class="bg-theme-offwhite-200 border-4 border-theme-secondary-200 mr-4 text-theme-secondary-200 rounded-full circle-timeline">
								<svg  xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 20 20" width="20" height="20"><g transform="matrix(0.8333333333333334,0,0,0.8333333333333334,0,0)"><path d="M13.5,9a5.5,5.5,0,1,0-7,5.287V15.5l-1,1,1,1v1l-1,1,1,1V22L8,23.5,9.5,22V14.287A5.5,5.5,0,0,0,13.5,9Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.000 7.498 A2.000 2.000 0 1 0 10.000 7.498 A2.000 2.000 0 1 0 6.000 7.498 Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.5,15.013a5.507,5.507,0,0,0,5.425.033L16,16v1.5h1.5l.5.525V19.5h1.317l1.062,1H22.5V18.379l-5.454-5.454a5.5,5.5,0,0,0-5.5-8.133" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8,7V2.5a2,2,0,0,1,4,0V4.753" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
							</div>
							<div class="ml-5 rounded-lg shadow bg-white">
								<div class="p-4">
									<p class="pb-2 font-semibold">{{ event.category }}</p>
									<p class="font-light">{{ event.event_string }}</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>


		<div v-if="showMoreButton" class="pager-link text-center">
			<button  v-on:click="loadMore" class="btn-ui">Laad meer</button>
		</div>
	</div>
</template>

<script>
	const axios = require('axios');
	export default {
		data() {
			return {
				events: [],
				categories: [],
				showFilterList: false,
				filter: [],
				take: 10,
				showMoreButton: true,
			}
		},

		props: ['unique-contact-id'],

		mounted: function() {
			this.init();
		},

		methods: { 
			init(){
				var vm = this;
				axios.post('/api/timeline', { ContactUid: this.uniqueContactId  }).then(function (response) {
					if(response.data){
						vm.events = response.data.Events;
						vm.categories = response.data.Categories;
						if(!response.data.IsMore){
							vm.showMoreButton = false;
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				})
			},

			loadMore(){
				var vm = this;
				this.take = this.take + 10;
				axios.post('/api/timeline', { ContactUid: this.uniqueContactId, Take:  this.take  }).then(function (response) {
					if(response.data){
						vm.events = response.data.Events;
						if(!response.data.IsMore){
							vm.showMoreButton = false;
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				})
			},

			showFilterDropdown(){
				this.showFilterList = true;
			},

			closeFilterDropdown(){
				this.showFilterList = false;
			},
		}
	}
</script>