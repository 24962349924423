import * as Sentry from '@sentry/browser';
if (process.env.NODE_ENV === 'production') {
	Sentry.init({ dsn: 'https://d139122faf344ef88a4c0b2c25815eb9@o244114.ingest.sentry.io/5910392' });
}

import 'alpinejs';

// window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */


 window.axios = require('axios');

 window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

 let token = document.head.querySelector('meta[name="csrf-token"]');

 if (token) {
 	window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
 } else {
 	console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
 }

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Vapor = require('laravel-vapor');
import Echo from "laravel-echo"
import Pusher from 'pusher-js'

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: '4257b5aabc319422a0b3',
	cluster: 'eu',
	forceTLS: true,
    enabledTransports: ['ws', 'wss'],
    wssPort:443,
    disableStats: true,
});
