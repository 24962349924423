export default class Assigned {
    constructor(name) {
        window[name] = this.data();
    }

    data() {
        return {
            residentialObjectUid: null,
            dropOuts: [],
            unassignedContacts: [],
            assignedContact: null,
            showModal: false,
            reason: '',
            reasonButtonClicked: false,
            init(residentialObjectUid) {
                this.residentialObjectUid = residentialObjectUid;
                this.getDropOuts();
                this.getAssignedContact();
                this.getUnassignedContacts();

                window.addEventListener(`reloadAssigned`, (e) => {
                    this.getAssignedContact();
                })
            },
            getAssignedContact() {
                var vm = this;
                return new Promise(function (resolve) {
                    vm.prepareRequest('post', '/api/residential-object/assigned');
                    vm.xhttp.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            if (this.responseText === undefined || this.responseText == null || this.responseText == '') {
                                vm.assignedContact = false;
                                return resolve(false);
                            }

                            var response = JSON.parse(this.responseText);
                            vm.assignedContact = response;
                            return resolve(response);
                        }
                    };

                    vm.xhttp.send(JSON.stringify({
                        residentialObjectUid: vm.residentialObjectUid,
                    }));
                });
            },
            getDropOuts() {
                var vm = this;
                return new Promise(function (resolve) {
                    vm.prepareRequest('post', '/api/residential-object/drop-outs');
                    vm.xhttp.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            var response = JSON.parse(this.responseText);
                            vm.dropOuts = response;
                            resolve(response);
                        }
                    };

                    vm.xhttp.send(JSON.stringify({
                        residentialObjectUid: vm.residentialObjectUid,
                    }));
                });
            },
            getUnassignedContacts() {
                var vm = this;
                return new Promise(function (resolve) {
                    vm.prepareRequest('post', '/api/residential-object/unassigned-contacts');
                    vm.xhttp.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            var response = JSON.parse(this.responseText);
                            vm.unassignedContacts = response;
                            console.log(vm.unassignedContacts);
                            resolve(response);
                        }
                    };

                    vm.xhttp.send(JSON.stringify({
                        residentialObjectUid: vm.residentialObjectUid,
                    }));
                });
            },
            prepareRequest(method, url) {
                var vm = this;
                vm.xhttp = new XMLHttpRequest();
                var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                vm.xhttp.open(method, url, true);
                vm.xhttp.setRequestHeader('Content-Type', 'application/json');
                vm.xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
            },
            reject() {
                console.log(this.reason);
                var vm = this;
                if (vm.reason == '') {
                    return;
                }
                return new Promise(function (resolve) {
                    vm.prepareRequest('post', '/api/residential-object/reject');
                    vm.xhttp.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            var response = JSON.parse(this.responseText);
                            vm.assignedContact = false;
                            vm.getDropOuts();

                            vm.reason = ''; // reset reason
                            vm.reasonButtonClicked = false;

                            resolve(response);
                        }
                    };

                    vm.xhttp.send(JSON.stringify({
                        residentialObjectUid: vm.residentialObjectUid,
                        reason: vm.reason,
                        assignedContact: vm.assignedContact.contact_id,
                    }));
                });
            },
            deleteDropOut(contactId) {
                var vm = this;
                console.log('d');
                return new Promise(function (resolve) {
                    vm.prepareRequest('post', '/api/residential-object/remove-drop-out/');
                    vm.xhttp.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            var response = JSON.parse(this.responseText);
                            vm.assignedContact = false;
                            vm.getDropOuts();
                            resolve(response);
                        }
                    };

                    vm.xhttp.send(JSON.stringify({
                        contactId: contactId,
                    }));
                });
            },
        };
    }
}
