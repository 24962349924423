/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';

import ('./helpers/href');
import ('./helpers/keybindings');
import ('./helpers/animate-login-screen');
import ('./helpers/upload');
import ('./helpers/offline');
import ('./helpers/autocomplete');
import ('./helpers/basic-lightbox');
import ('./helpers/value-to-clipboard');
import ('./helpers/address-filler');
import ('./helpers/trix');
import ('./helpers/trixFile');
import '@nextapps-be/livewire-sortablejs'; // using sortable.js

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
import Vue from 'vue';
Vue.component('alert-toast-success', require('./components/alert-toast-success.vue').default);
Vue.component('alert-toast-warning', require('./components/alert-toast-warning.vue').default);
Vue.component('alert-toast-danger', require('./components/alert-toast-danger.vue').default);
Vue.component('push-toast-danger', require('./components/push-toast-danger.vue').default, );
Vue.component('push-toast-warning', require('./components/push-toast-warning.vue').default);
Vue.component('push-toast-success', require('./components/push-toast-success.vue').default);
Vue.component('push-toast-notify', require('./components/push-toast-notify.vue').default);
Vue.component('textfield', require('./components/ui/textfield.vue').default);
Vue.component('numberfield', require('./components/ui/numberfield.vue').default);
Vue.component('map-box', require('./components/mapbox/mapbox.vue').default);
Vue.component('map-box-click-able', require('./components/mapbox/mapbox-clickable.vue').default);
Vue.component('tabs', require('./components/tabs/tabs.vue').default);
Vue.component('tab', require('./components/tabs/tab.vue').default);
Vue.component('line-chart', require('./components/chart/line.vue').default);
Vue.component('doughnut-chart', require('./components/chart/doughnut.vue').default);
Vue.component('survey-doughnut', require('./components/chart/survey-doughnut.vue').default);
Vue.component('heatmap-chart', require('./components/chart/heatmap.vue').default);
Vue.component('sortable', require('./components/sortable/sortable.vue').default);
Vue.component('contact-preferences-table', require('./components/contact-preferences/contact-preferences-table.vue').default);
Vue.component('timeline', require('./components/timeline.vue').default);

if(document.getElementById("app")){
	new Vue().$mount('#app');
}

Chart.defaults.scale.gridLines.display = false;
Chart.defaults.scale.ticks.display = false;
Chart.defaults.line.spanGaps = false;
Chart.defaults.global.responsive = true;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import clipboard from './helpers/clipboard';
new clipboard('.when-click-add-to-clipboard');

import ChatMessages from './alpine/ChatMessages.js';
new ChatMessages("ChatMessages");

import FormResults from './alpine/FormResults.js';
new FormResults("FormResults");

import AssignDigitally from './alpine/AssignDigitally.js';
new AssignDigitally("AssignDigitally");

import Api from './alpine/Api.js';
new Api("Api");

import AutoComplete from './alpine/AutoComplete.js';

import Modal from './alpine/Modal.js';
new Modal("Modal");

import Assigned from './alpine/Assigned.js';
new Assigned("Assigned");

import MailTest from './alpine/MailTest.js';
new MailTest("MailTest");

import AdditionalQuestion from './alpine/AdditionalQuestion.js';
new AdditionalQuestion("AdditionalQuestion");

import Repeater from './alpine/Repeater.js';
new Repeater("Repeater");

console.log('Updated')

