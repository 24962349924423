<template>
	<div class="border bg-white shadow-lg mb-10 p-0 shadow-lg flex-1 text-theme-secondary-200" id="ChartWrapper">
		<div class="flex flex-row items-center px-3 mt-3">
				
			<div class="flex flex-col justify-end leading-none ">
				<span class="title .. text-md font-semibold opacity-50">{{label}}</span>
			</div>
		</div>
		<canvas class="w-full" id="Chart"></canvas>
	</div>
</template>


<script>

import Chart from 'chart.js'
import 'chartjs-plugin-colorschemes';

export default {
	data() {
		return {
			totalCounter: 1,
			lines: [],
			data: {
				totalCount: '#',
				labels: ['1', '2' , '3', '4', '5'],
				values: [100,200,380, 400 ,420],
			}
		}
	},
	props: ['label','json','color','stacked'],
	mounted: function() {

		if( this.json )	this.data  = JSON.parse(this.json);

		console.log( this.data)
		var config = {
			type: 'doughnut',
			data: {
				datasets: [{
					// backgroundColor: [
					// 	this.shadeColor( this.color, 0),
					// 	this.shadeColor( '#ddd', 0),
					// ],
					data: this.data.values,
					
				}],
				labels: this.data.labels
			},
			options: {
				animation: {
					animateScale: true,
					animateRotate: true
				},
				legend: {
					display: true,
					position: 'left',
				},
				layout: {
					padding: {
						left: 10,
						right: 10,
						top: 15,
						bottom: 10
					}
				},
				annotation: {
					events: ["click"],
					annotations: this.lines,
				},
				plugins: {
					colorschemes: {
						scheme: 'brewer.Paired12'
					}
				}
			},
		};
		var ctx =  this.$el.querySelector('#Chart').getContext('2d');
		window.myLine = new Chart(ctx, config);
	},

}
</script>