export default class Clipboard {

    constructor(selector) {
        const vm = this;
        this.selector = selector
        var observer = new MutationObserver(function(mutations) {
            if ( document.querySelectorAll(this.selector) ) {
                 vm.setCopyAble();
            }
        }); 
        observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});
        

        vm.setCopyAble();
 
    }

    setCopyAble(){
        const vm = this;
        document.querySelectorAll(this.selector).forEach(function (item) {
            item.addEventListener("click", function () {
    
                vm.copy(this.innerHTML);
    
                this.classList.remove('copied')
    
                setTimeout(() => {
                    this.classList.add('copied')
                }, 10)
    
                setTimeout(() => {
                    this.classList.remove('copied')
                }, 500)
    
    
            });
        });
    }

    copy(str) {
        let el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}



