<template>

    <div class="w-full h-full relative">
        <div class="map " id="map-render"></div>
    </div>

</template>

<script>
const axios = require('axios');

const accesToken = document.querySelector('meta[name="mapbox_access_token"]').content;
mapboxgl.accessToken = accesToken;

export default {
    data() {
        return {
            map: null
        }
    },
    props: ['data-source', 'max-zoom', 'not-hover-able', 'show-project'],
    methods: {
        initMap() {
            this.map = new mapboxgl.Map({
                container: 'map-render',
                center: [5.613478, 52.206921],
                zoom: 9
            })

            this.map.setStyle('mapbox://styles/wilfredlepelaar/ck3a1cnms01mm1cr39tntl18i');

            this.addListeners();

            return this.initMarkers();
        },
        addListeners() {
            window.addEventListener("mapbox-reload", (event) => {
                this.initMarkers();
            });
        },
        initMarkers() {
            var vm = this;

            const bounds = new mapboxgl.LngLatBounds();

            axios.get(vm.dataSource).then(function (response) {
                for (var i = 0; i < response.data.length; i++) {
                    var MarkerHref = document.createElement('a');
                    if (response.data[i]['is_project_location'] && vm.showProject) {
                        MarkerHref.className = 'map-marker-new';
                    } else if (response.data[i]['url']) {
                        MarkerHref.className = 'map-marker';
                        MarkerHref.href = response.data[i]['url']
                    } else {
                        MarkerHref.className = 'map-marker-no-route';
                    }

                    var popup = new mapboxgl.Popup({offset: 12})
                        .setHTML('<div class="flex w-full justify-between"><div class="text-xs pt-1 px-2 text-white">' + response.data[i]['name'] + '</div></div>');

                    const marker = new mapboxgl.Marker(MarkerHref).setLngLat(response.data[i]['location']).setPopup(popup).addTo(vm.map);
                    if (!vm.notHoverAble) {
                        const markerDiv = marker.getElement();

                        markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
                        markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
                    }


                    bounds.extend(response.data[i]['location']);
                }

                const zoom = vm.maxZoom ? vm.maxZoom : 9;

                vm.map.fitBounds(bounds, {
                    padding: {top: 50, bottom: 50, left: 50, right: 50},
                    maxZoom: zoom,
                    duration: 0
                });
                vm.map.addControl(new mapboxgl.NavigationControl());

            })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.initMap();

        this.map.on("wheel", event => {
            if (event.originalEvent.ctrlKey || event.originalEvent.altKey || event.originalEvent.metaKey) {
                return;
            }
            event.preventDefault();
        });
    }
}
</script>
