<template>
  <div>
    <ul class="list-reset flex border-b">
      <li v-for="tab in tabs" class="mr-1 text-theme-secondary-200 cursor-pointer">

        <a :data-item="tab.item" class="inline-block py-4 px-8 hover:text-theme-secondary-300 hover:bg-theme-offwhite-100 font-semibold border-white border-b-4 hover:border-theme-offwhite-200"
        :class="{ 'border-theme-secondary-100 hover:text-theme-secondary-300 border-b-4': tab.isActive }"
        
        @click="selectTab(tab)">
        {{ tab.title }}  
      </a>
    </li>
  </ul>

  <div class="px-4">
    <slot></slot>
  </div>

</div>
</template>

<script>

export default {
 data () {
  return {
   tabs: []
 }
},

created() {

  this.tabs = this.$children;

  window.addEventListener('hashchange', this.hashHandler, false);
  this.hashHandler();
  
},

methods: {

  selectTab(stab) {
    var hash = this.slugify(stab.title);
    this.tabs.forEach(tab => { tab.isActive = ( this.slugify(tab.title) == this.slugify(hash)); });
  },

  hashHandler() {
    var vm = this;
    if( window.location.hash ){ 
      setTimeout(function(){
        var hash =  window.location.hash.replace(/#/g, '')
        vm.tabs.forEach(tab => { tab.isActive = ( vm.slugify(tab.title) == vm.slugify(hash)); });
      },10);
    }
 },


  slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
  }
}

}
</script>