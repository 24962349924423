<template>
	<div class="flex flex-col items-end">
		<label v-for="(message, index) in messages" :key="index" class="alert-toast" :class="{ show: message.showMe }" @click="hideMessage(message)">
		<div class="my-2 inline-flex items-center p-1 bg-orange-400 leading-none text-white rounded-full p-0 shadow-xl text-teal text-sm">
			<span class="inline-flex px-5" v-html="message.msg"></span>
			<span class="inline-flex bg-orange-600 text-white rounded-full p-2 justify-center items-center">
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 24 24" height="24" width="24"><g transform="matrix(1,0,0,1,0,0)"><path d="M 19,17.35c0-0.887,0.219-1.761,0.636-2.544l2.142-4.022c0.414-0.718,0.167-1.635-0.55-2.049 c-0.689-0.397-1.569-0.187-2.005,0.479L16.5,13.5l1.617-8.821c0.155-0.814-0.38-1.599-1.193-1.754 c-0.786-0.15-1.551,0.345-1.739,1.123L13.5,11.5V2c0-0.828-0.672-1.5-1.5-1.5S10.5,1.172,10.5,2v9.5L8.816,3.683 c-0.174-0.81-0.972-1.325-1.782-1.151S5.709,3.504,5.883,4.314L7.5,15.5l-2.987-2.608c-0.603-0.568-1.552-0.54-2.12,0.062 s-0.54,1.552,0.062,2.12c0.011,0.01,0.022,0.02,0.033,0.03l5.257,7C8.594,22.995,9.77,23.499,11,23.5h3.5 C18.055,23.5,19,20.723,19,17.35z" stroke="currentColor" fill="none" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
			</span>
		</div>
	</label>

	</div>
</template>

<script>

export default {
	data() {
		return {
			messages: [],
		}
	},
	props: ['autoclose'],
	mounted: function() {
		let vm = this; 
		let user = document.head.querySelector('meta[name="user"]');
		window.Echo.private('toast-channel.' + user.content).listen('.warning', (data) => {

			var index = vm.messages.push({
				'msg': data.message,
				'showMe': true,
				'isVisible': true,
				'autoclose': data.autoclose
			});
			
			if( data.autoclose > 0 ) vm.autocloseMessage( vm.messages[index - 1] )

		});
	},
	methods: { 
		hideMessage(message){
			message.showMe = false;
			setTimeout(function () { message.isVisible = false; }, 500);
		},
		autocloseMessage(message){
			let vm = this;
			setTimeout(function () { vm.hideMessage( message ) }, message.autoclose);
		}
	}
}
</script>