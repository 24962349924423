<template>
  <div>
    <div id="questions-list">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
  import Sortable from 'sortablejs';
  export default {
   data () {
    return {

    }
  },
  props: {

  },

  mounted() {
    this.init()
    var vm = this;
    document.addEventListener("livewire:load", function(event) {
      window.livewire.hook('element.updated', () => {
          vm.init(); 
           window.livewire.rescan()   
     });
    });
  },

  methods: { 
    init(){
      var vm = this;
      var QuestionTypeEl =   document.querySelector('#question-types')
      
      var QuestionEl = document.querySelector('sortable')
      if( !QuestionEl ){
        var QuestionEl = document.querySelector('#questions-list')
      }
      // Question type list
      Sortable.create(QuestionTypeEl, {
        group: {
          name: 'shared',
          pull: 'clone',
          put: false,
        },

        sort: false,
        // animation: 150,
        onEnd: function (evt) {
          // The place where it should be put in the list. 
          const newIndex =evt.newIndex;
          const QuestionType = evt.clone.dataset.sort
          window.livewire.emit('addQuestion', [newIndex, QuestionType])
        },
      });
      // Questions list
      Sortable.create(QuestionEl, {
        group: {
          name: 'shared',
          pull: 'clone',
        },
        handle: '.move-able',
        ghostClass: "survey-dragging",  // Class name for the drop placeholder
        // animation: 150,
        onEnd: function (evt) {
          // Catches moves in the list.
          const oldIndex = evt.oldIndex;
          const newIndex =evt.newIndex;
          window.livewire.emit('changeQuestionOrder', [oldIndex, newIndex])
        },
      });
    }

  }

}
</script>