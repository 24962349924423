export default class MailTest {
	constructor(name) {
		window[name] = this.data()
	}
	data() {
        return {
            // arr
            tags: [],
            settings: [],

            // Routes
            routeRead: '',
            routeUpdate: '',

            // settings
            showNewQuestionField: false,
            newQuestionField: '',


            init(routeRead, routeUpdate) {
                var vm = this;

                vm.routeRead    = routeRead;
                vm.routeUpdate  = routeUpdate;

                vm.getData();
            },

            getData(){
                var vm = this;
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
                        vm.tags = responce.tags;
                        vm.settings = responce.settings.length > 0 ? JSON.parse(responce.settings) : [];
					}
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', vm.routeRead, true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send();
				}, 100)
            },

            toggleNewQuestionField(){
                var vm = this;
                vm.showNewQuestionField ^= true;
            },

            addNewQuestionToSettings(){
                var vm = this;
                var obj = {};
                obj["question"] = vm.newQuestionField;
                obj["answers"] = [];
                vm.settings.push(obj);
                vm.newQuestionField = '';
                vm.toggleNewQuestionField();
                vm.addNewAnswerToQuestion(vm.settings.length - 1)
            },

            addNewAnswerToQuestion(questionIndex, answer = null, tagId = null){
                var vm = this;
                var obj = {};

                obj["answer"] = answer;
                obj["tag_id"] = tagId;

                vm.settings[questionIndex].answers.push(obj);
            },

            deleteAnswerFromQuestion(questionIndex, answerIndex){
                var vm = this;
                vm.settings[questionIndex].answers.splice(answerIndex, 1)
            },

            deleteQuestion(questionIndex){
                var vm = this;
                vm.settings.splice(questionIndex, 1)
                this.saveSettings()
            },

            saveSettings(){
                var vm = this;
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
                       console.log(responce)
					}
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', vm.routeUpdate, true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
                        settings: vm.settings,
                    }));
				}, 100)
            }
        }
    }
}
