import {asset} from '@codinglabs/laravel-asset'


window.asset = asset;


export default class AssignDigitally {
	constructor(name) {
		window[name] = this.data()
	}
	data() {
		return {
			data: [],
			SubPlanUid: null,
			xhttp: null,
			Filters: [],
			loading: true,
			loaded: false,
			objectsCount: 0,
			showFiltersDropdown: false,
			filterPreference: [],
			assignedResidentialObject: [],
			activeFilters: [],
			toastMessage: '',
			toastDisplay: false,
			newChanges: false,
			showModal: false,
			currentDrawResidentialObject: "",
			currentDrawName: null,
			optionsPoints: [],


			optionFormOptions: [],
			selectedOptionFormOptions: [],

			rangeValues: [],
            tagFilteredBy: [],
            tagFilteredBySelectedOption: [],

			overWriteAssign: [],
			availableContacts: [],

			overwrittenContact: [],
			tab: 'settings',
			errorMessage: '',
			hasError: false,
			tooltip: [],
			optionFormId: null,
			loadingMessage: "Gegevens ophalen",
			tags: [],


			zipCodes: [],
			showZipCodeField: false,
			zipCodeInput: '',

			init(SubPlanUid, Filters, optionFormId,  Options = null) {
				var vm = this;
				this.loading = true;
				this.SubPlanUid = SubPlanUid
				this.filterPreference = Filters
				this.optionFormId = optionFormId;

				this.getContacts().then(function (responce){
					vm.availableContacts = responce;
				});

				this.getResidentialObjectUids().then(function(responce){
					vm.calc(responce)
				});

				if( Options ){
					const OptionsObj = JSON.parse(Options)
					this.getOptionFormQuestions().then(function (){
						if(OptionsObj !== null){
							vm.setOptionsinit(Object.values(OptionsObj));
						}
					});
				}

				this.getTags();

				this.loading = false;
			},

			filterContacts(contacts){
				// console.log( this.assignedResidentialObject )
				// console.log( contacts )
				// if(!this.assignedResidentialObject){
				// 	console.log('1')
				// }else{
				// 	console.log('2')
				// }
				// this.assignedResidentialObject.filter(function (item) {
				// 	console.log(item)
				// })

				return contacts

			},

			calc(residentialObjectsUid){
				var vm = this;
				var promises = []

				residentialObjectsUid.forEach(uid => {
					promises.push(vm.getInfoForResidentialObject(uid));
				});


				Promise.all(promises).then((values) => {
					vm.objectsCount = values.length;
					vm.data = Object.assign({}, ...values);
					vm.loaded = true;
                    vm.loading = false;
                    vm.newChanges = false;
					vm.setAssignedInit();
				});


			},

			getInfoForResidentialObject(residentialObjectUid){
				var vm = this;
				return new Promise((resolve, reject) => {
					vm.prepareRequest('post', '/api/assign-digitally');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							resolve(responce)
						}
					}

					vm.xhttp.send(JSON.stringify({
						residentialObjectUid: residentialObjectUid,
						optionFormId: vm.optionFormId,
						filters: vm.activeFilters,
					}));
				  });
			},


			getResidentialObjectUids(){
				var vm = this;
				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/assign-digitally/residential-objects');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							resolve(responce);
						}
					}

					vm.xhttp.send(JSON.stringify({
						subPlanUid: vm.SubPlanUid,
					}));
				});
			},

			ContactPreferences: [],
			getContactPreference(contactId){
				var vm = this;

				if(typeof vm.ContactPreferences[contactId] != 'undefined'){
					return vm.ContactPreferences[contactId];
				}

				return new Promise(function(resolve, reject) {
					vm.prepareRequest('post', '/api/assign-digitally/preferences');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							vm.ContactPreferences[contactId] = responce;
							resolve(responce);
						}
					}

					vm.xhttp.send(JSON.stringify({
						optionFormId: vm.optionFormId,
						contactId: contactId,
					}));
				});

			},

			makeRequest(){
				var vm = this;
				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/assign-digitally');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							vm.objectsCount = responce.totalCount
							delete responce.totalCount

							resolve(responce.residentialObjects)
						}
					}

					vm.xhttp.send(JSON.stringify({
						SubPlanUid: vm.SubPlanUid,
						Filters: vm.activeFilters,
					}));
				});
			},

			setAssignedInit(){
				var vm = this;

				Object.entries(vm.data).forEach(function (entry){
					if(entry[1].assigned_to == null){
						return;
					}

					var isContactInContactList = entry[1].contacts.findIndex(function(contact, index) {
						if(contact.contactId == entry[1].assigned_to){
							return true;
						}
						return false;
					});

					//Overwrite
					if(isContactInContactList < 0){
						vm.overWriteAssign[entry[1].object_id] = true;
						setTimeout(function(){
							vm.overwrittenContact[entry[1].object_id] = entry[1].assigned_to
						}, 1000);
					}

					vm.assignedResidentialObject[entry[1].object_id] = entry[1].assigned_to;
				});

			},

			assignResidentialObject(object, contactId, test){
				var objectId = object.object_id
				object.status = 'Wijzigingen niet opgeslagen'
				if(this.isOverwriteActive(objectId)){
					this.overwrittenContact[objectId] = contactId
				}

				if(this.isAssignedToResidentialObject(objectId, contactId)){
					this.newChanges = true;
					delete this.assignedResidentialObject[objectId];
					this.assignedResidentialObject[objectId] = null

					if(this.isOverwriteActive(objectId)){
						this.overwrittenContact[objectId] = null;
					}

					return;
				}

				if(this.isContactAssignedToAnyResidentialObject(objectId, contactId)){
					this.newChanges = true;
					// Remove assigned residential object
					// And assign new one
					const values = Object.values(this.assignedResidentialObject);
					const keys = Object.keys(this.assignedResidentialObject);
					const contactIndex = values.indexOf(contactId);
					var key = keys[contactIndex];

					delete this.assignedResidentialObject[key];

					this.assignedResidentialObject[objectId] = contactId;

					return;
				}else{
					this.newChanges = true;
					this.assignedResidentialObject[objectId] = contactId;
					return
				}


				console.error("Something went wrong while assigning")
			},

			isAssignedToResidentialObject(objectId, contactId){
				if( this.assignedResidentialObject[objectId] !== undefined && this.assignedResidentialObject[objectId] ==  contactId) {
					return true;
				}
				return false;
			},

			isContactAssignedToAnyResidentialObject(objectId, contactId){
				const values = Object.values(this.assignedResidentialObject);
				if(values.includes(contactId)){
					if(this.isAssignedToResidentialObject(objectId, contactId)){
						return false;
					}
					return true;
				}
				return false;
			},

			etResidentialObjectUids(){
				var vm = this;
				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/assign-digitally/residential-objects');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							resolve(responce);
						}
					}

					vm.xhttp.send(JSON.stringify({
						subPlanUid: vm.SubPlanUid,
					}));
				});
			},

			assignSubmit(){
				var vm = this;
				this.loading = true;
				if(Object.keys(vm.assignedResidentialObject).length == 0){
					return;
				}

				var vm = this;
				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/assign-digitally');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							vm.loadingMessage = "Toewijzen gelukt, gegevens opslaan"
							vm.loaded = false;
							vm.getResidentialObjectUids().then(function(residentialObjectResponse){
								vm.calc(residentialObjectResponse)
							});
						}
					}

					vm.xhttp.send(JSON.stringify({
						SubPlanId:  vm.SubPlanUid,
						assignedResidentialObject: JSON.stringify(Object.entries( vm.assignedResidentialObject )),
					}));
				});

			},

			setFilter(preference){
				var vm = this;

				vm.loading = true;

				if(vm.activeFilters.includes(preference)){
					vm.activeFilters.splice(vm.activeFilters.indexOf(preference), 1)
				}else{
					vm.activeFilters.push(preference);
				}

				vm.loadingMessage = "De filtering wordt toegepast"
				vm.loaded = false;
				vm.getResidentialObjectUids().then(function(residentialObjectResponse){
					vm.calc(residentialObjectResponse)
				});
			},

			restoreFilters(){
				var vm = this;
				vm.loading = true;
				vm.activeFilters = [];
				vm.loadingMessage = "De filtering wordt hersteld"
				vm.loaded = false;
				vm.getResidentialObjectUids().then(function(residentialObjectResponse){
					vm.calc(residentialObjectResponse)
				});
			},

			doesContactMeetPrice(objectPrice, contactIncome){
				if(contactIncome >= objectPrice){
					return true;
				}
				return false;
			},

			toggleFiltersDropdown(){
				this.showFiltersDropdown ^= true
			},

			prepareRequest(method, url){
				var vm = this
				vm.xhttp = new XMLHttpRequest();
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				vm.xhttp.open(method, url, true);
				vm.xhttp.setRequestHeader('Content-Type', 'application/json');
				vm.xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
			},

			getSourceUrl(fileUrl){
				return "/api/show-file/" +  encodeURIComponent(window.btoa(fileUrl))
			},

			draw(){
				var vm = this;

				this.showModal = true;
				vm.makeDrawAnimation()
				vm.tab = 'contacts'


				vm.assignedResidentialObject = [];

				this.loading = true;

				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
						if(typeof responce.Error != "undefined"){
							vm.hasError = true;
							vm.errorMessage = responce.Message
							vm.loading = false;
							vm.showModal = false;
							return
						}
						setTimeout(function(){
							for (var k in responce) {
								if (responce.hasOwnProperty(k)) {
									if(responce[k] !== null){
										vm.assignedResidentialObject[k] = responce[k];
										vm.newChanges = true;
										vm.showModal = false;
									}
								}
							}
							vm.loading = false;
						}, 1000);

					}
				}
				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/assign-digitally/draw', true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
						SubPlanId:  vm.SubPlanUid,
						optionsPoints: JSON.stringify(Object.assign({}, vm.optionsPoints)),
					}));
				}, 100)
			},

			timeout(ms) {
				return new Promise(resolve => setTimeout(resolve, ms));
			},

			makeDrawAnimation(){
				var amountProduces =  0.4;
				var startTime = 1000;
				var residentialObjects =  Object.entries(this.data);
				var vm  = this
				Object.entries(vm.data).forEach(function (entry){
					var mapped = [];
					vm.currentDrawName = null
					setTimeout(function () {
						// vm.currentDrawResidentialObject = entry[0];
						mapped = entry[1].contacts.map(({name}) => ({ name }));
						if(mapped.length > 0){

							setInterval(function(){
								vm.currentDrawName = mapped[Math.floor(Math.random() * mapped.length)].name;
							}, 800);
							setInterval(function(){
								vm.currentDrawResidentialObject = entry[0];
							}, 500);
						// setTimeout(function () {
						// 	clearInterval(eval(new String('timer_' + entry[1].object_id)));
						// }, 1000);
					}


				}, startTime * amountProduces);
					amountProduces = amountProduces * amountProduces;

				});

				// console.log(new Date().getTime() / 1-000)

				// var randTimer =setInterval(function(){ vm.makeDrawAnimation() }, 500);
			},
			selectRandomContact(){
				var residentialObject = this.selectRandomObject();
				if(residentialObject.contacts.length == 0){
					return this.selectRandomContact();
				}
				var contacts = residentialObject.contacts;
				var randomContact  = Object.entries(contacts)[Math.floor(Math.random() * Object.entries(contacts).length)][1];
				this.assignedResidentialObject[residentialObject.object_id] = randomContact.contactId;
			},




			selectRandomObject(){
				return Object.entries(this.data)[Math.floor(Math.random() * Object.entries(this.data).length)][1] ;
			},

			getContactPreferences(selectedContact){
				var vm = this;
				const contactArr = [];
				Object.keys(vm.data).forEach(function (key){
					var contacts = vm.data[key].contacts
					Object.keys(contacts).forEach(function (contactKey){
						if(contacts[contactKey].hasOwnProperty("contactId")){
							if(contacts[contactKey].contactId == selectedContact.contactId){
								contactArr.push({object_id : vm.data[key].object_id, preference: contacts[contactKey].preference});
							}
						}
					});
				});
				return contactArr;
			},

			setOptionsinit(optionsObj){
				for (let i = 0; i < optionsObj.length; i++) {
					const setting = optionsObj[i];

					var index = Object.values(this.optionFormOptions).findIndex(function(question) {
						if (question === undefined || setting == undefined){
							return false;
						}
						if(question.Uid == setting.uid)
							return true;
					});

					if (index !== undefined && index !== -1){

						this.addOptionFormRule(Object.values(this.optionFormOptions)[index]);
						this.addOption(setting.key, setting.points, setting.uid, setting.answer)
					}else{
						// We work with a non question condition
						if(setting.key == 'zipCode'){
							this.zipCodes.push(setting.uid);
							this.addOption(setting.key, setting.points,  setting.uid);
						}else if(setting.key == 'exclusion_tag'){
							this.addOption(setting.key, null,  setting.uid);
						}else if(setting.key == 'tag'){
							this.addOption(setting.key, setting.points,  setting.uid);
						}else{
							this.addOption(setting.key, setting.points)
						}
					}
				}
			},

			addOption(key, points = null, keyUid = null, questionAnswer = null){
				var args = {};

				var optionAlreadyExist = this.optionsPoints.findIndex(function(option) {
					if (option === undefined){
						return false;
					}

                    if(option.key == key && option.key !== 'tag')
						return true;

					if(option.key == key && option.uid == keyUid)
						return true;
				});


				if(!(key == 'zipCode'|| key == 'exclusion_tag' || key == 'option_form_question')){
					if (optionAlreadyExist !== undefined && optionAlreadyExist !== -1){

						delete this.optionsPoints[optionAlreadyExist]
					}
				}

				if (keyUid !== null && key == 'option_form_question'){
					this.rangeValues[keyUid + questionAnswer] = points;

					args.uid = keyUid;

					// Check if we already have a value of it:
					var questionAlreadyExist = this.optionsPoints.findIndex(function(question, index) {
						if (question === undefined){
							return false;
						}
						if(question.uid == args.uid && questionAnswer == question.answer)
							return true;
					});

					if (questionAlreadyExist !== undefined && questionAlreadyExist !== -1){
						delete this.optionsPoints[questionAlreadyExist]
					}
				}else if(keyUid !== null && (key == 'tag_filter_by' || key == 'tag' || key == 'zipCode'|| key == 'exclusion_tag')){

					args.uid = keyUid;
					this.rangeValues[key + keyUid] = points;
				}else{
					this.rangeValues[key] = points;
				}

				if (questionAnswer !== null){
					args.answer = questionAnswer;
				}

				if(points != null){
					args.points = points

                    /*
                     * exclude range when the tag_filter_by is rules_out or required
                     */
                    if (key === 'tag_filter_by') {
                        if (points === 'rules_out' || points === 'required') {
                            if (this.tagFilteredBy.includes(keyUid) === false) {
                                this.tagFilteredBy.push(keyUid);
                            }
                        } else {
                            this.tagFilteredBy = this.tagFilteredBy.filter(e => e !== keyUid);
                        }
                    }
				}

				args.key = key

				this.optionsPoints.push( args );
			},
            isFilterTagByExluded(keyUid) {
                return this.tagFilteredBy.includes(keyUid);
            },
			addOptionFormRule(question){
				if(this.isSelected(question.uid)){
					return
				}

				this.selectedOptionFormOptions.push(question);
			},

			deleteOptionFormRule(index, questionUid){
				for (const key in this.optionsPoints) {
					if(this.optionsPoints[key].uid == questionUid){
						// Remove ranges
						delete this.rangeValues[this.optionsPoints[key].uid + this.optionsPoints[key].answer];

						// Remove answers
						delete this.optionsPoints[key];
					}
				}

				// Remove quesiton
				delete this.selectedOptionFormOptions[index]
			},

			handleChangeboxChange(key){
				var index = this.isInOptionPoints(key);
				if(index === false){
					return this.addOption(key);
				}

				return this.optionsPoints.splice(index, 1);
			},

			isInOptionPoints(key){
				var vm = this;
				var index = this.optionsPoints.findIndex(function(condition, index) {
					if(condition == null){
						return false;
					}
					if(condition.key == key){
						if(key == 'filled_option_form_complete'){
							vm.sortAllContactByKeyBool('hasCompletedForm')
						}
						return true;
					}
				});

				if (index !== undefined && index !== -1){
					return index;
				}


				if(key == 'filled_option_form_complete'){
					vm.sortAllContactByKey('name')
				}

				return false
			},

			isSelected(uid){
				var questionAlreadyExist = this.selectedOptionFormOptions.findIndex(function(question, index) {
					if (question === undefined){
						return false;
					}
					if(question.Uid == uid){
						return true;
					}
				});
				if (questionAlreadyExist !== undefined && questionAlreadyExist !== -1){
					return true;
				}
				return false
			},

			getRangeValue(key){
				if (key in this.rangeValues){
					return this.rangeValues[key];
				}

				return 0;
			},


			submitBestBuyer(){
				var vm = this;
				vm.tab = 'contacts'
				vm.loading = true;
				vm.hasError = false;

                // reset ideal buyer class
                const idealBuyerElements = document.getElementsByClassName('ideal-buyer');
                for (let i = 0; i < idealBuyerElements.length; i++) {
                    console.log(`reseting ideal buyer class ${i}`, idealBuyerElements[i]);
                    idealBuyerElements[i].classList.remove("text-red-500");
                    idealBuyerElements[i].classList.remove("font-extrabold");
                }

				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var responce = JSON.parse(this.responseText)
						if(typeof responce.Error != "undefined"){
							vm.hasError = true;
							vm.tab = 'settings'
							vm.errorMessage = responce.Message
							vm.loading = false;
						}
						var assignedArr = responce.Assigned
						var pointsArr = responce.Points


						// setTimeout(function(){
							// Assign
							for (var k in assignedArr) {
								if (assignedArr.hasOwnProperty(k)) {
									if(assignedArr[k] !== null){
										vm.assignedResidentialObject[k] = assignedArr[k];
										vm.newChanges = true;
									}
								}
							}

							// Points
							for (var residentialObjectUid in pointsArr) {
								for (const [points, contacts] of Object.entries(pointsArr[residentialObjectUid])) {
									contacts.forEach(function (item, index) {

										var el = document.getElementById('points-' + item +'-' + residentialObjectUid)

										if(el){
											el.parentNode.parentNode.classList.remove('hidden')
											el.textContent = '(' +  points + ' punten)'
										}

									});
								}
							}
							vm.loading = false;
						// }, 1000);
					}
				}

				var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				xhttp.open('post', '/api/assign-digitally/best-buyer', true);
				xhttp.setRequestHeader('Content-Type', 'application/json');
				xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				setTimeout(function() {
					xhttp.send(JSON.stringify({
						subPlanId:  vm.SubPlanUid,
						optionsPoints: JSON.stringify(Object.assign({}, vm.optionsPoints)),
					}));
				}, 100)
			},
			forceOverWrite(objectId){
				if (objectId in this.overWriteAssign){
					delete this.overWriteAssign[objectId];
					return
				}
				this.overWriteAssign[objectId] = true;
			},
			isOverwriteActive(objectId){
				return objectId in this.overWriteAssign;
			},
			getContacts(){

				var vm = this;

				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/assign-digitally/contacts');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							return resolve(responce);
						}
					}

					vm.xhttp.send(JSON.stringify({
						subPlanId:  vm.SubPlanUid,
						optionFormId: vm.optionFormId,
					}));
				});




				// var xhttp = new XMLHttpRequest();
				// xhttp.onreadystatechange = function() {
				// 	if (this.readyState == 4 && this.status == 200) {
				// 		var responce = JSON.parse(this.responseText)
				// 		vm.availableContacts = responce
				// 	}
				// }
				// var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				// xhttp.open('post', '/api/assign-digitally/contacts', true);
				// xhttp.setRequestHeader('Content-Type', 'application/json');
				// xhttp.setRequestHeader('X-CSRF-TOKEN', csrf);
				// setTimeout(function() {
				// 	xhttp.send(JSON.stringify({
				// 		subPlanId:  vm.SubPlanUid,
				// 		optionFormId: vm.optionFormId,
				// 	}));
				// }, 100)
			},
			getOverwrittenContactProperty(objectId, propertyName){
				if(this.overwrittenContact[objectId] === undefined || this.overwrittenContact[objectId] === null){
					return '-'
				}
				for (var i=0; i < this.availableContacts.length; i++) {
					if (this.availableContacts[i].id === this.overwrittenContact[objectId]) {
						return this.availableContacts[i][propertyName];
					}
				}
			},
			closeError(){
				this.errorMessage = ''
				this.hasError = false;
			},
			getOptionFormQuestions(){

				var vm = this;
				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/assign-digitally/option-form-questions');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							vm.optionFormOptions = responce
							resolve(responce)
						}
					}

					vm.xhttp.send(JSON.stringify({
						subPlanId: vm.SubPlanUid,
					}));
				});
			},
			getTags(){

				var vm = this;
				return new Promise(function(resolve, reject) {

					vm.prepareRequest('post', '/api/assign-digitally/get-tags');
					vm.xhttp.onreadystatechange = function() {
						if (this.readyState == 4 && this.status == 200) {
							var responce = JSON.parse(this.responseText)
							vm.tags = responce
							resolve(responce)
						}
					}

					vm.xhttp.send(JSON.stringify({
						subPlanId: vm.SubPlanUid,
					}));
				});
			},
			sortContactByKey(index, key){
				if (typeof this.data[index] === 'undefined') {
					return;
				}

				this.data[index].contacts =  this.data[index].contacts.sort(function(a, b) {
					var x = a[key]; var y = b[key];
					return ((x < y) ? -1 : ((x > y) ? 1 : 0));
				});
			},
			sortContactByKeyBool(index, key){
				if (typeof this.data[index] === 'undefined') {
					return;
				}


				this.data[index].contacts =  this.data[index].contacts.sort(function(a, b) {
					var x = a[key]; var y = b[key];
					return (x === y)? 0 : x? -1 : 1;
				});

				console.log(this.data[index].contacts);
			},
			addZipcode(){
				this.zipCodes.push(this.zipCodeInput)
				this.showZipCodeField = false
				this.zipCodeInput = ''
			},
			removeZipCode(index, zipCode){
				for (const key in this.optionsPoints) {
					if(this.optionsPoints[key].uid == zipCode){
						// Remove ranges
						delete this.rangeValues['zipCode' + zipCode];

						// Remove answers
						delete this.optionsPoints[key];
					}
				}

				return this.zipCodes.splice(index, 1);
			},
			isNumber(event){
				var charCode = (event.which) ? event.which : event.keyCode;

				// Backspace -  enter - delete
				if(charCode==8 || charCode==13 || charCode==46) return true;

				//48 => 0 , 57 => 9 ,  96 => numpad 0 , 105 => numpad 9
				if ((charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) return false;

				if( this.zipCodeInput.length >= 4){
				  return false
				}

				return true;
			},
			handleExclusionTagClick(key, tagId){
				var index = this.isTagExcluded(key, tagId);
				if(index === false){
					return this.addOption(key, null, tagId);
				}

				return this.optionsPoints.splice(index, 1);
			},
			isTagExcluded(key, tagId){
				var index = this.optionsPoints.findIndex(function(condition, index) {
					if(condition == null){
						return false;
					}

					if(condition.key == key && condition.uid == tagId){
						return true;
					}
				});

				if (index !== undefined && index !== -1){
					return index;
				}

				return false
			},
			sortAllContactByKeyBool(key){
				var vm = this;
				Object.keys(this.data).forEach(function (index){
					vm.data[index].contacts =  vm.data[index].contacts.sort(function(a, b) {
						var x = a[key]; var y = b[key];
						return (x === y)? 0 : x? -1 : 1;
					});
				})
			},
			sortAllContactByKey(key){
				var vm = this;
				Object.keys(this.data).forEach(function (index){
					vm.data[index].contacts =  vm.data[index].contacts.sort(function(a, b) {
						var x = a[key]; var y = b[key];
						return ((x < y) ? -1 : ((x > y) ? 1 : 0));
					});
				})
			},
	}
	}
}
