export default class Modal {
    constructor(name) {
        window[name] = this.data()
    }

    data() {
        return {
            methods: ["GET", "POST", "PUT", "DELETE"],
            options: {
                duration: 1000,
            },
            modalOpen: false,
            data: [],
            loading: false,
            error: false,
            isLoading(loading) {
                this.loading = loading;
            },
            toggleModal() {
                console.log('open modal', this.modalOpen);
                this.modalOpen = !this.modalOpen;
            },
            call(action, body = {}, method = 'POST') {
                this.isLoading(true);

                // catch invalid methods
                if (!this.methods.includes(method)) {
                    return;
                }

                // send to endpoint
                fetch(action, {
                    method: method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content,
                    },
                    body: method === 'GET' ? null : JSON.stringify(body),
                })
                    .then(response => {
                        // collect response data (oke / failed) based on the http status code
                        return response.json().then(data => ({
                            error: !response.ok, // [MDN] the ok read-only property of the Response interface contains a boolean stating whether the response was successful (status in the range 200-299) or not.
                            status: response.status, // status code of the response (e.g., 200 for a success)
                            errors: typeof data.errors !== 'undefined' ? data.errors : null, // array with error messages (eg: Field X must be a number)
                            message: data.message, //
                            options: typeof data.options !== 'undefined' ? data.options : [],
                            data: typeof data.data !== 'undefined' ? data.data : [],
                        }))
                    })
                    .then(response => {
                        if (response.error === true) {
                            return;
                        }

                        // data
                        this.data = response.data;

                        // redirect (if requested)
                        if (typeof response.options.redirect !== 'undefined') {
                            setTimeout(() => {
                                // redirect
                                window.location.replace(response.options.redirect);
                            }, this.options.duration);
                        }

                        // reload (if requested)
                        if (this.options.reload === true) {
                            // reload
                            window.location.reload();
                        }
                    })
                    .catch((e) => {
                        // todo
                    })
                    .finally(() => {
                        /*setTimeout(() => {
                            this.isLoading(false);
                            this.toggleModal();
                        }, this.options.duration);*/
                    })
            }
        }
    }

}
