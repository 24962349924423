// default options
const defaultOptions = {
    duration: 2000,
    minKeywordLength: 3,
    hasToggleButton: false,
    'confirmModal': null,
}

window.AutoComplete = (options = {}) => {
    return {
        options: Object.assign({}, defaultOptions, options), // options,
        methods: ["GET", "POST", "PUT", "DELETE"],
        data: [],
        items: [],
        loading: false,
        error: false,
        keyword: '',
        hasSearched: false,
        isSearchFieldOpen: true,
        init() {
            if (this.options.hasToggleButton === '1') {
                this.isSearchFieldOpen = false;
            } else {
                this.isSearchFieldOpen = true;
            }
        },
        isLoading(loading) {
            this.loading = loading;
        },
        setSearchStatus(hasSearched) {
            this.hasSearched = hasSearched;
        },
        clearKeyword() {
            this.keyword = '';
            this.clearItems();
            this.setSearchStatus(false);
        },
        clearItems() {
            this.items = [];
        },
        close() {
            this.clearKeyword();
            this.setSearchStatus(false);
        },
        autocomplete(action, body = {}) {
            if (this.keyword.length < this.options.minKeywordLength) {
                return;
            }

            this.isLoading(true);

            this.setSearchStatus(false);

            if (this.keyword.length <= 0) {
                this.clearItems();
                return;
            }

            body.keyword = this.keyword;

            // send to endpoint
            fetch(action, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content,
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    // collect response data (oke / failed) based on the http status code
                    return response.json().then(data => ({
                        error: !response.ok, // [MDN] the ok read-only property of the Response interface contains a boolean stating whether the response was successful (status in the range 200-299) or not.
                        status: response.status, // status code of the response (e.g., 200 for a success)
                        errors: typeof data.errors !== 'undefined' ? data.errors : null, // array with error messages (eg: Field X must be a number)
                        message: data.message, //
                        options: typeof data.options !== 'undefined' ? data.options : [],
                        data: typeof data.data !== 'undefined' ? data.data : [],
                    }))
                })
                .then(response => {
                    if (response.error === true) {
                        this.clearItems();
                        return;
                    }

                    this.items = response.data.items;

                    this.isLoading(false);

                    this.setSearchStatus(true);
                })
                .catch((e) => {
                    // todo
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isLoading(false);
                    }, this.options.duration);
                })
        },
        call(action, body = {}, method = 'POST') {
            this.isLoading(true);

            this.setSearchStatus(false);

            // catch invalid methods
            if (!this.methods.includes(method)) {
                return;
            }

            // send to endpoint
            fetch(action, {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content,
                },
                body: method === 'GET' ? null : JSON.stringify(body),
            })
                .then(response => {
                    // collect response data (oke / failed) based on the http status code
                    return response.json().then(data => ({
                        error: !response.ok, // [MDN] the ok read-only property of the Response interface contains a boolean stating whether the response was successful (status in the range 200-299) or not.
                        status: response.status, // status code of the response (e.g., 200 for a success)
                        errors: typeof data.errors !== 'undefined' ? data.errors : null, // array with error messages (eg: Field X must be a number)
                        message: data.message, //
                        options: typeof data.options !== 'undefined' ? data.options : [],
                        data: typeof data.data !== 'undefined' ? data.data : [],
                    }))
                })
                .then(response => {
                    if (response.error === true) {
                        return;
                    }

                    this.clearKeyword();

                    this.isLoading(false);

                    if (typeof response.options.dispatch !== 'undefined' && response.options.dispatch.length > 0) {
                        // send custom event
                        let event = new CustomEvent(response.options.dispatch);
                        window.dispatchEvent(event);
                    }
                })
                .catch((e) => {
                    // todo
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isLoading(false);
                    }, this.options.duration);
                })
        }
    }
}
