<template>
  <div  class="mt-10" v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
  export default {
   data () {
    return {
     isActive: false,
   }
 },
 props: {
  title: {
    required: true
  },
  selected: {
    default: false
  },
  item: {
    default: null
  }
},

mounted() {
  this.isActive = this.selected;
}

}
</script>